import $ from 'jquery';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'slick-carousel';
import Headroom from 'headroom.js';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { initA11yFunctions } from './accessibility.js';

const Mangrove = {
  init() {
    this.cache();
    this.events();
    this.initHeadroom();
    this.initSliders();
    this.toggleBannerOpen(true)
    this.initAccessibility();
  },
  cache() {
    this.$doc = $(document);
    this.$win = $(window);
    this.$html = $('html');
    this.$body = $('body');
    this.$mainWrap = $('main');
    this.$header = $('.js-header');
    this.$mobileNav = $('.js-mobile-nav');
    this.$hamburger = $('.js-hamburger');
    this.$mainWrap.attr('paddingtop', parseInt(this.$mainWrap.css('paddingTop')))
  },
  events() {
    this.$doc.ready(() => this.fireOnDOMReady())
    this.$win.load(() => this.fireOnload())
    this.$win.on('scroll', () => this.fireScroll())
    this.$win.on('resize', () => this.fireResize())
    this.$doc.on('click', '.js-hamburger', event => this.showMobileNav(event));
    this.$doc.on('click', '.js-share', event => this.openShareWindow(event));
    this.$doc.on('click', '.header__translate, .menu-item--translate a, .gtranslate__close', event => this.toggleGTranslate(event));
    this.$doc.on('click', '.site-banner__close', event => this.closeBanner(event));
    this.$doc.on('click', '.header__search', event => this.toggleSearchExpand(event));
    this.$doc.on('click', '.js-team-popup', event => this.toggleTeamBio(event));
    this.$doc.on('click', '.js-team-list', event => this.onTeamFilter(event));
    this.$doc.on('click', '.js-mobile-nav .menu-item-has-children > a', event => this.handleChildren(event));
    this.$doc.on('click', '.video-description__video', event => this.playPauseVideo(event));
    this.$doc.on('click', '.resumator-job-link', () => ScrollTrigger.refresh());
    $('.nav--main li.menu-item-has-children').on('mouseenter', event => this.handleSubmenuIn(event));
    $('.nav--main li.menu-item-has-children').on('mouseleave', event => this.handleSubmenuOut(event));
    this.$win.load(() => this.initLinkAltText())
  },
  initAccessibility(){
    // initA11yFunctions()
  },
  fireOnDOMReady() {
    $('body').addClass('dom-initialized');
    this.initAnimate()
  },
  initLinkAltText() {
    $("a").each(function () {
      if($(this).find('img')){
        if ($(this).find('img').attr('alt')) $(this).attr('alt', $(this).find('img').attr('alt'));
        else $(this).attr('alt', 'Image');
      }
    });
  },
  fireScroll() {
    this.resetMobileNav()
    this.toggleBannerOpen()
    this.resetSearch()
  },
  fireResize() {
    this.resetTeam()
  },
  fireOnload() {
    this.appendGoogleTranslate()
  },
  initHeadroom() {
    let theHeader = document.querySelector('.js-header');
    let headroom = new Headroom(theHeader, { offset: 100, tolearnce: 5 });
    headroom.init();
  },
  initSliders() {
    $(".carousel").each(function(index){
      var slideSpeed = $(this).attr('data-slide') ? $(this).attr('data-slide') : 500;

      $('.js-slider-hero').slick({
        dots: true,
        arrows: false,
        infinite: true,
        fade: true,
        cssEase: 'linear',
        speed: slideSpeed
      });
      $('.js-slider-content').slick({
        dots: false,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        speed: slideSpeed
      });
      $('.js-slider-testimonial').slick({
        dots: true,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        speed: slideSpeed,
      });
    });
  },
  closeBanner(event) {
    event.preventDefault()
    const $banner = $('.js-banner')

    this.$header.css('paddingTop', 0)
    this.$mainWrap.css('paddingTop', `${this.$mainWrap.attr('paddingtop')}px`)
    $banner.css('top', `-${$banner.outerHeight()}px`).addClass('is-closed')
  },
  toggleBannerOpen(init) {
    const $banner = $('.js-banner')

    if ( !$('.js-banner.is-closed').length && $banner.length > 0 ) {
      if ( $(window).scrollTop() > 0 ) {
        this.$header.css('paddingTop', 0)
        $banner.css('top', `-${$banner.outerHeight()}px`).addClass('is-toggled')

      } else {
        const mainPadding = parseInt(this.$mainWrap.attr('paddingtop')) + $banner.outerHeight();

        this.$header.css('paddingTop', `${$banner.outerHeight()}px`)
        this.$mainWrap.css('paddingTop', `${mainPadding}px`)
        $banner.css('top', 0).removeClass('is-toggled')

      }
    }

    if (init && $banner.length > 0) {
      $banner.addClass('is-loaded')
    }

  },
  resetSearch(event){
    if ( $('.header__search.is-expanded').length) {

      $('.header__search.is-expanded').removeClass('is-expanded')

    }
  },
  toggleSearchExpand(event) {
    const $search_elm = $(event.currentTarget);
    
    if ( event.currentTarget !== event.target ) {
      return false;
    }

    if ( this.$win.width() <= 959 ) {
      window.location.href = $search_elm.data('searchLink');
      
      return false;
    }

    if ( $search_elm.find('input').val().length && $search_elm.hasClass('is-expanded') ) {
      $search_elm.find('form').trigger('submit');
    }
    
    $search_elm.toggleClass('is-expanded')

  },
  openShareWindow(event) {
    let link = $(event.currentTarget).attr('href');
    if (link.substr(0, 6) != 'mailto') {
      event.preventDefault();
      window.open(link, '', 'width=600, height=400');
    }
  },
  mobileSubMenuData(event) {
    $('.nav--mobile .sub-menu').each(function() {
      const height = $(this).innerHeight();
      $(this).attr('data-height', height)

    })
  },
  showMobileNav(event) {
    event.preventDefault();
    this.mobileSubMenuData();
    this.$mobileNav.toggleClass('is-active');
    this.$hamburger.toggleClass('is-active');

    if ( this.$mobileNav.hasClass('is-active') ) {
      $('html').css('overflow', 'hidden');
      this.$hamburger.attr("aria-pressed", "true");
    } else {
      $('html').css('overflow', 'auto');
      this.$hamburger.removeAttr("aria-pressed");
    }
  },
  resetMobileNav() {
    this.$mobileNav.removeClass('is-active');
    this.$hamburger.removeClass('is-active');
    this.$hamburger.removeAttr('aria-pressed');
  },
  handleSubmenuIn(e) {
    const subMenuTop = $('.header__content').innerHeight();
    const navMainHeight = $('.nav--main').innerHeight() + 7;
    
    $('.nav--main ul.sub-menu').css('top', `${subMenuTop - navMainHeight}px`)

  },
  handleSubmenuOut(e) {

    const subMenuTop = $('.header__content').innerHeight();
    
    $('.nav--main ul.sub-menu').css('top', subMenuTop/2)

  },
  handleChildren(e) {
    const {target} = e;
    const submenu = $(target).find('+ .sub-menu');
    e.preventDefault();

    submenu.css('height', 0)

    $(target).toggleClass('is-open');

    if ( $(target).hasClass('is-open') ) {
      submenu.css('display', 'block')

      setTimeout(() => {
        submenu.css('height', submenu.data('height'))
      }, 50)

    } else {
      submenu.css('height', 0)
    }
  },
  scrollTo$element($elm) {
    if ($elm.length) {
      $('html, body').animate({
        scrollTop: $elm.offset().top-80
      }, 1000);
    }
  },
  appendGoogleTranslate() {
    if ($('.skiptranslate.goog-te-gadget').length) {
      $('.header__gtranslate').append($('.skiptranslate.goog-te-gadget'));
    }
  },
  toggleGTranslate(e) {
    const { preventDefault, target, currentTarget } = e;
    const location = $(target).closest('footer').length ? 'footer' : 'header';
    const selector = `${location} .gtranslate`;
    preventDefault()

    if (currentTarget !== target) {
      return false;
    }

    if ( location == 'footer' ) {

      $('.menu-item--translate').append( $('.header__gtranslate').removeClass('header__gtranslate').addClass('footer__gtranslate') )

    } else {

      if ( !$('.header__top .gtranslate').length ) {

        $('.header__top').append( $('.footer__gtranslate').removeClass('footer__gtranslate').addClass('header__gtranslate') )

      }

    }

    $(selector).toggleClass('is-shown')

    return false;
  },
  toggleTeamBio(e) {
    const { currentTarget } = e;
    const $teamMember = $(currentTarget).closest('.one-team-member');
    const $memberBio = $teamMember.find('.bio-popup');
    const $memberBioInfo = $teamMember.find('.bio-info');
    const $bioContainer = $teamMember.nextAll('.bio-container:first');
    const isDesktop = $(window).width() >= 960;
    const isNotActive = !$teamMember.hasClass('is-active');

    $('.bio-container').each(function() {
      const $elm = $(this)
      if ( $elm.hasClass('is-open') && $elm[0] !== $bioContainer[0] ) {
        $elm.hide()
      }
    });

    !isNotActive && $bioContainer.slideUp();
    $('.bio-popup').slideUp();
  
    $('.one-team-member').removeClass('is-active');
    isNotActive && $teamMember.addClass('is-active');
    
    if ( !isDesktop ) {
      isNotActive && $memberBio.slideDown();
      this.scrollTo$element($memberBioInfo);
    } else {
      isNotActive && $bioContainer.slideDown();
      $bioContainer.addClass('is-open');
      $bioContainer.html( $memberBio.html() );
    }
  },
  onTeamFilter(e) {
    e.preventDefault();

    const selectedGroup = $(e.currentTarget).attr('data-slug');
    $('.bio-container').hide();
    $('.team-grid').css('display', 'none');
    $(`#${selectedGroup}-grid`).css('display', 'flex');

    ScrollTrigger.refresh()
  },
  resetTeam() {
    if ( $('.team').length ) {
      $('.bio-container').hide();
      $('.bio-popup').hide();
      $('.one-team-member').removeClass('is-active');
      $('.team-grid').css('display', 'none');
      $('.member-groups + .team-grid').css('display', 'flex');
    }
  },
  initAnimate() {
    const { animateHide, animateFrom } = this;

    gsap.registerPlugin(ScrollTrigger);

    $('.anim').each(function() {
      const elem = this;

      ScrollTrigger.create({
        trigger: elem,
        onEnter: function(st) { animateFrom(st) },
        onEnterBack: function(st) { animateFrom(st, -1) },
        onLeave: function(st) { animateHide(st) },
        // markers: true
      });

      if ( $(this).hasClass('lazyload') ){
        $(this).load(function() {
          ScrollTrigger.refresh()
        })
      }
      if ( $(this).find('.lazyload, .lazyloading').length ) {
        const img_total = $(this).find('img').length;
        let img_count = 0;

        $(this).find('img').each(function() {
          $(this).load(function() {
            img_count++;
            if ( img_count == img_total ) {
              ScrollTrigger.refresh()
            }
          })
        })
      }
    })
  },
  animateFrom({trigger}, direction) {
    direction = direction | 1;

    var x = 0, y = direction * 100;
    if(trigger.classList.contains("anim--reveal-left")) {
      x = -50;
      y = 0;
    } else if(trigger.classList.contains("anim--reveal-right")) {
      x = 50;
      y = 0;
    }
    gsap.fromTo(trigger, {x: x, y: y, autoAlpha: 0}, {
      duration: 1.25,
      x: 0,
      y: 0,
      autoAlpha: 1,
      ease: "expo",
      overwrite: "auto"
    });
  },
  animateHide({trigger}) {
    gsap.set(trigger, {autoAlpha: 0});
  },
  playPauseVideo(event) {
    const {currentTarget} = event;
    const video = $(currentTarget).find('video')[0];
    const $button = $(currentTarget).find('svg');
    const method = video.paused ? 'play' : 'pause';
    
    $(video).addClass('has-played');

    if (method == 'play') {
      $button.hide()
    } else {
      $button.show()
    }
    video[method]()
  }
};

Mangrove.init();
